.element {
  position: relative;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 30px 40px rgba(0, 0, 0, 0.1);
  background-color: #decead;
}

.element.borderRadius {
  border-radius: 15px;
  overflow: hidden;
}

.element,
.element > canvas {
  width: 100%;
  height: 100%;
}

.element > .areaDetails {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  display: flex;
  align-items: flex-end;
  gap: 20px;
  pointer-events: none;
  animation: floor-indicator-entry 1s ease-in-out;
  animation-delay: 1s;
  animation-fill-mode: backwards;
  background-image: linear-gradient(0deg, #0008, transparent);
}

.element > .areaDetails > .floorIndicator {
  width: 100px;
  height: 100px;
  background-image: url(../../../assets/graphics/floor-indicators.svg);
  background-size: auto 100px;
  background-repeat: no-repeat;
  background-position-y: 0px;
  flex-shrink: 0;
}

/* Dark color scheme overrides. */
@media (prefers-color-scheme: dark) {
  .element {
    background: #2f352f;
  }
}

@keyframes floor-indicator-entry {
  from {
    opacity: 0;
    transform: translateY(5%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
