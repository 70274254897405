.coverImage {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 15px;
}

.sidebarContainer {
  margin-top: 100px;
  position: sticky;
  top: 20px;
}

.menuIcon {
  display: none;
}

.expandedGalleryMap {
  width: 100%;
  height: 200px;
}

.logoImage {
  background-image: url(../../../assets/images/application-logo-light.png);
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center;
  width: 57px;
  height: 40px;
}
@media (prefers-color-scheme: dark) {
  .logoImage {
    background-image: url(../../../assets/images/application-logo-dark.png);
  }
}

@media screen and (max-width: 768px) {
  .sidebarContainer {
    display: none;
  }

  .menuIcon {
    display: block;
  }
}
