.element {
  position: relative;
  cursor: pointer;
}

.element.fullWidth {
  width: 100%;
}

.element::after {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border-radius: 5px;
  z-index: -1;
  pointer-events: none;
  background-color: transparent;
  transition: 0.1s background-color ease-in-out;
}

.element:hover::after {
  background-color: #dbd3b6;
}

.element:active::after {
  background-color: #cdc4a6;
}

/* Dark color scheme overrides. */
@media (prefers-color-scheme: dark) {
  .element:hover::after {
    background-color: #333333;
  }

  .element:active::after {
    background-color: #202020;
  }
}
