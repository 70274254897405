.assets {
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  width: 100%;
}

.assets > * > .expandedGalleryMap {
  width: 100%;
  height: 300px;
}

.assets > .map {
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 120px;
  height: 100px;
}

.highlightIcon {
  width: 20px;
  height: 20px;
  animation: rotate 2s infinite ease-in-out;
}

@keyframes rotate {
  from,
  to {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2) rotate(10deg);
  }
}
