.element {
  background-color: #f3f0ee;
  aspect-ratio: 1;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}

.edit {
  position: absolute;
  background-image: url(../../../assets/graphics/edit-icon.svg);
  background-repeat: no-repeat;
  background-size: auto 70%;
  background-position: center;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #f3eacb;
  right: 5px;
  bottom: 5px;
}

.edit > input[type='file'] {
  display: none;
}

/* Dark color scheme overrides. */
@media (prefers-color-scheme: dark) {
  .element {
    background-color: #070707;
  }
}
