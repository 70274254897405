.indicator {
  border-radius: 100%;
  width: 10px;
  height: 10px;
  background-color: #91cca3;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.6s ease;
}

.indicator.active {
  background-color: #5d6c61;
}

@media (prefers-color-scheme: dark) {
  .indicator {
    background-color: #5d6c61;
  }

  .indicator.active {
    background-color: #91cca3;
  }
}
