.item {
  flex: 1 0;
  overflow: hidden;
}

.item:nth-child(1) {
  z-index: 3;
  order: 2;
}

.item:nth-child(2) {
  transform: scale(0.9, 0.9) translateX(20%);
  z-index: 1;
  order: 1;
}

.item:nth-child(3) {
  transform: scale(0.9, 0.9) translateX(-20%);
  z-index: 2;
  order: 3;
}

.item > div > h1,
.item > div > h5,
.item > div > h6 {
  text-align: center;
  margin: 0 10%;
}
