.element {
  background-color: #f3eacb;
  box-shadow: 0 30px 40px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.element.grid {
  border-radius: 15px;
  overflow: hidden;
  display: grid;
  gap: 5px;
}

.element.grid.single {
  grid-template-columns: 1fr;
  grid-template-rows: 300px;
}

.element.grid.double {
  grid-template-columns: 1fr;
  grid-template-rows: 190px 190px;
}

.element.grid.tripple {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 150px 200px;
}

.element.grid.tripple > img:nth-child(3) {
  grid-column: 1 / span 2;
}

.element > img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.element.expanded {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

/* Dark color scheme overrides. */
@media (prefers-color-scheme: dark) {
  .element {
    background-color: #070707;
  }
}
