.element {
  background: #decead;
  border-radius: 999px;
}

.option {
  width: 20px;
  height: 20px;
  margin: 0;
  cursor: pointer;
  opacity: 0.5;
  transition: 0.1s opacity ease-in-out;
}

.option:hover,
.activeOption {
  opacity: 1;
}

/* Dark color scheme overrides. */
@media (prefers-color-scheme: dark) {
  .element {
    background: #222222;
  }
}
