.progress {
  position: absolute;
  bottom: 15px;
  height: 20px;
  width: 90%;
  padding: 3px;
  background: rgba(246, 248, 253, 0.7);
  border-radius: 10px;
}

.progressFill {
  background: #5d6c61;
  width: 0;
  border-radius: 10px;
  height: 100%;
  transition: width 0.6s ease;
}

@media (prefers-color-scheme: dark) {
  .progress {
    background: rgba(26, 26, 26, 0.7);
  }

  .progressFill {
    background: #f3eacb;
  }
}
