.element {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.element.column {
  flex-direction: column;
}

.element.column {
  flex-direction: column;
}

.element.gap {
  gap: 20px;
}

.element.padding {
  padding: 20px;
}

.element.horizontalPadding {
  padding-left: 20px;
  padding-right: 20px;
}

.element.verticalPadding {
  padding-top: 20px;
  padding-bottom: 20px;
}

.element.fullWidth {
  width: 100%;
}

.element.relative {
  position: relative;
}

.element.debug {
  border: 1px dashed magenta;
}

.element.debug > * {
  border: 1px dotted magenta;
}
