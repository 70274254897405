.textAreaWrapper {
  position: relative;
  display: grid;
  width: 100%;
}

.textAreaWrapper::after {
  content: attr(data-replicated-value) ' ';
  word-break: break-all;
  white-space: pre-wrap;
  visibility: hidden;
}

.textAreaWrapper > textarea {
  resize: none;
  overflow: hidden;
  outline: none;
}

.textAreaWrapper > textarea,
.textAreaWrapper::after {
  font-family: 'SF Pro';
  font-weight: normal;
  font-size: 1rem;
  color: #a89e7b;

  border: none;
  background: #decead;
  padding-bottom: 25px;
  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}

.textAreaWrapper > textarea::placeholder {
  color: #a89e7b;
}

.textAreaWrapper > .spacing,
.textAreaWrapper.spacing::after {
  border-radius: 10px;
  padding: 10px 10px 25px 10px;
}

.textAreaWrapper > .textAreaCounter {
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-family: 'SF Pro';
  font-weight: normal;
  font-size: 0.75rem;
  color: #5d6c61;
}

@media (prefers-color-scheme: dark) {
  .textAreaWrapper > textarea,
  .textAreaWrapper::after {
    background: #313131;
    color: #a89e7b;
  }

  .textAreaWrapper > textarea::placeholder {
    color: #a89e7b;
  }

  .textAreaWrapper > .textAreaCounter {
    color: #f3eacb;
  }
}
