.element {
  cursor: pointer;
  pointer-events: all;
}

.element.primary,
.element.primaryLightScheme {
  background: #5d6c61;
}

.element.primary:hover,
.element.primaryLightScheme:hover {
  background: #6b5950;
}

.element.primary:active,
.element.primaryLightScheme:active {
  background: #455249;
}

.element.primary *,
.element.primaryLightScheme * {
  color: #f3eacb;
}

.element.light {
  background: #c4b699;
}

.element.light:hover {
  background: #a99d85;
}

.element.light:active {
  background: #9e937b;
}

.element.light * {
  color: #5d6c61;
}

/* .element.lightDarkScheme {
  background: #191919;
}

.element.lightDarkScheme:hover {
  background: #111111;
}

.element.lightDarkScheme:active {
  background: #000;
}

.element.lightDarkScheme * {
  color: #fff;
} */

.element.rounded {
  border-radius: 20px;
}

.element.circle {
  border-radius: 100%;
}

.element.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.element > p,
.element > h3 {
  margin: 0.5em 1.25em;
}

.element > img {
  display: block;
  padding: 25%;
}

.fullWidth {
  width: 100%;
  text-align: center;
}
