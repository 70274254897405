.imageContainer {
  width: 100%;
  display: flex;
  gap: 20px;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  aspect-ratio: 1/1.2;
  max-height: 50vh;
  width: auto;
}

.imageContainer::-webkit-scrollbar {
  display: none;
}

.imageWrapper {
  border-radius: 10px;
  scroll-snap-align: center;
  min-width: 80%;
  height: auto;
}

.imageWrapper:first-child {
  padding-left: 10%;
  box-sizing: initial;
}

.imageWrapper:last-child {
  padding-right: 10%;
  box-sizing: initial;
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}
