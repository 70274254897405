*[data-animation='fade-in'] {
  animation: fade-in 0.3s ease-in;
}

*[data-animation='fade-out'] {
  animation: fade-out 0.3s ease-out;
}

*[data-animation='slide-in-bottom'] {
  animation: slide-in-bottom 0.3s ease-in forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slide-in-bottom {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
