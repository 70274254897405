html {
  font-size: 15px;
  /* Some browsers implement text size inflation such as mobile Safari in 
  landscape. Setting this property to none disabled this behaviour. */
  -webkit-text-size-adjust: none;
}

html * {
  box-sizing: border-box;
  /* All elements have a padding and margin of zero by default are should remain
  so. Elements are spaced by Spacing elements and pushed to the inside by
  container elements. */
  margin: 0;
  padding: 0;
  /* The following properties are used to indicate unstyled elements. */
  font-family: 'Courier New';
  color: magenta;
}

html body {
  background-color: #f3eacb;
  touch-action: pan-y;
}

html button {
  font-family: 'SF Pro';
  font-weight: normal;
  font-size: 1rem;
}

/* Dark color scheme overrides. */
@media (prefers-color-scheme: dark) {
  html body {
    background-color: #070707;
  }
}
