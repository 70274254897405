.element {
  position: fixed;
  bottom: 20px;
  left: 20px;
  right: 20px;
  max-width: 1000px;
  margin: 0 auto;
  display: inline-flex;
  justify-content: flex-end;
  pointer-events: none;
}
