.container {
  width: 100%;
  height: auto;
  border-radius: 20px;
}

.container.primary {
  background: #f3eacb;
}

.container.secondary {
  background: #dbd3b6;
}

@media (prefers-color-scheme: dark) {
  .container.primary {
    background: #070707;
  }

  .container.secondary {
    background: #313131;
  }
}
