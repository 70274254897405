.element {
  position: fixed;
  z-index: 200;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  backdrop-filter: blur(2px);
  display: flex;
  transition: 0.15s opacity;
  pointer-events: none;
}

.element > img {
  position: absolute;
  object-fit: contain;
  object-position: center;
  z-index: 2;
}
