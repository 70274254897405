.input {
  font-family: 'SF Pro';
  font-size: 1.5rem;
  font-weight: normal;
  color: #5d6c61;
  background: #decead;

  width: 100%;
  padding: 16px;
  border-radius: 20px;
  border: none;
  outline: none;
  border: 2px solid transparent;
}

.input::placeholder {
  color: rgb(92, 108, 97, 0.5);
}

.input:focus {
  border: 2px solid #5d6c61;
}

@media (prefers-color-scheme: dark) {
  .input {
    background: #313131;
    color: #f3eacb;
  }

  .input::placeholder {
    color: rgb(244, 234, 202, 0.3);
  }
}
