.element {
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #5d6c61;
}

.element p,
.element h2 {
  color: #fff;
}
