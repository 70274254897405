.clickable:hover {
  border-radius: 20px;
  background: #ebd9b7;
  transition: background 0.2s ease-in-out;
}

.draggedOver {
  background: #ebd9b7;
  transition: background 0.2s ease-in-out;
}

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 20px;
  transition: background 0.2s ease-in-out;
}

.placeholder.box {
  width: 100%;
  height: 100%;
  min-height: 250px;
  padding: 15px;
}

.placeholder.inline {
  min-width: 200px;
  width: 200px;
  height: 200px;
  background: #decead;
}

.placeholder.clickable {
  cursor: pointer;
}

.placeholder > .uploadIcon {
  background-image: url(../../../assets/graphics/upload-icon-dark.svg);
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center;
  width: 64px;
  height: 64px;
}

.placeholder > p {
  color: #a89e7b;
  line-height: 1.5;
  text-align: center;
  margin: 0;
}

.placeholder > p > strong {
  color: #5d6c61;
}

.placeholder > input[type='file'] {
  display: none;
}

@media (prefers-color-scheme: dark) {
  .clickable:hover {
    background: rgb(40, 40, 40);
  }

  .draggedOver {
    background: rgb(40, 40, 40);
  }

  .placeholder.inline {
    background: #313131;
  }

  .placeholder > .uploadIcon {
    background-image: url(../../../assets/graphics/upload-icon-light.svg);
  }

  .placeholder > p {
    color: #a89e7b;
  }

  .placeholder > p > strong {
    color: #f3eacb;
  }
}
