@font-face {
  font-family: 'SF Pro';
  font-style: normal;
  font-weight: bold;
  src: url(../assets/fonts/sfpro-bold.otf);
}

@font-face {
  font-family: 'SF Pro';
  font-style: normal;
  font-weight: normal;
  src: url(../assets/fonts/sfpro-regular.otf);
}
