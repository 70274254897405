.element {
  background: #decead;
  border-radius: 20px;
}

.element p strong {
  cursor: pointer;
}

/* Dark color scheme overrides. */
@media (prefers-color-scheme: dark) {
  .element {
    background: #313131;
  }
}
