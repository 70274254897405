.image {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 20px;
  object-fit: cover;
}

.removeButton {
  position: absolute;
  top: 20px;
  right: 10px;

  background-image: url(../../../assets/graphics/close-icon-light.svg);
  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position: center;
  width: 36px;
  height: 36px;
  background-color: rgba(244, 234, 202, 0.8);
  border-radius: 50%;
  padding: 8px;

  cursor: pointer;
}

@media (prefers-color-scheme: dark) {
  .removeButton {
    background-image: url(../../../assets/graphics/close-icon-dark.svg);
    background-color: rgb(8, 7, 5, 0.8);
  }
}
