html body p {
  font-family: 'SF Pro';
  font-weight: normal;
  font-size: 1rem;
  color: #5d6c61;
}

html body p > strong {
  all: inherit;
  display: inline;
  font-weight: bold;
}

html body a {
  font-family: 'SF Pro';
  font-weight: normal;
  font-size: 1rem;
  color: #a89e7b;
  text-decoration: none;
}

html body li {
  font-family: 'SF Pro';
  font-weight: normal;
  font-size: 1rem;
  color: #5d6c61;
}

html body h1 {
  font-family: 'SF Pro';
  font-weight: normal;
  font-size: 1rem;
  color: #5d6c61;
}

html body h2 {
  font-family: 'SF Pro';
  font-weight: bold;
  font-size: 1.75rem;
  color: #5d6c61;
}

html body h3 {
  font-family: 'SF Pro';
  font-weight: bold;
  font-size: 1.5rem;
  color: #5d6c61;
}

html body h4 {
  font-family: 'SF Pro';
  font-weight: normal;
  font-size: 0.9rem;
  color: #5d6c61;
}

html body h5 {
  font-family: 'SF Pro';
  font-weight: bold;
  font-size: 1rem;
  color: #5d6c61;
}

html body h6 {
  font-family: 'SF Pro';
  font-weight: normal;
  font-size: 0.9rem;
  color: #a89e7b;
}

html body span {
  font-family: 'SF Pro';
  font-weight: normal;
  font-size: 0.8rem;
  color: #7d796b;
}

/* Dark color scheme overrides. */
@media (prefers-color-scheme: dark) {
  html body p {
    color: #f3eacb;
  }

  html body a {
    color: #a89e7b;
  }

  html body li {
    color: #f3eacb;
  }

  html body h1 {
    color: #f3eacb;
  }

  html body h2 {
    color: #f3eacb;
  }

  html body h3 {
    color: #f3eacb;
  }

  html body h5 {
    color: #f3eacb;
  }

  html body h6 {
    color: #a89e7b;
  }

  html body span {
    color: #f3eacb;
    opacity: 0.5;
  }
}
