.edit {
  background-image: url(../../../assets/graphics/edit-icon.svg);
  background-repeat: no-repeat;
  background-size: auto 70%;
  background-position: center;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #f3eacb;
}

.edit.changePicture {
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.edit > input[type='file'] {
  display: none;
}

@media (prefers-color-scheme: dark) {
  .edit {
    background-color: #070707;
  }
}
