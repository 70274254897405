.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 15px;
  border-radius: 20px;
  border: 2px solid transparent;
}

.card.clickable {
  cursor: text;
}

.card.onFocus {
  border: 2px solid #5d6c61;
}

.card.primary {
  background: #f3eacb;
}

.card.secondary {
  background: #decead;
}

@media (prefers-color-scheme: dark) {
  .card {
    color: #5d6c61;
  }

  .card.primary {
    background: #070707;
  }

  .card.secondary {
    background: #313131;
  }
}
