.checkboxContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  text-align: left;
  flex: 1;
  cursor: pointer;
}

.checkboxContainer .checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  min-width: 20px;
  margin-right: 15px;
  outline: none;
  border-radius: 5px;
  border: 2px solid #dbd3b6;
  appearance: none;
  -webkit-appearance: none;
}

.checkboxContainer .checkbox.checked {
  background-color: #dbd3b6;
  transition: all 300ms;
}
